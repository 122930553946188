@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Exo+2&display=swap');

*,
::before,
::after {
  box-sizing: border-box;
}

body {
  overflow-y: scroll;
}

html,
body,
#app {
  margin: 0;
  padding: 0;
  height: 100%;
  min-width: 320px;
  background-color: #04020f;
  font-family: 'Exo 2', sans-serif;
}

img,
video {
  max-width: 100%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

.Portal {
  position: fixed;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.NavLink {
  color: #fff;
  text-decoration: none;
}

.FlexLink {
  display: flex;
}

.HoverUnderlineAnimation {
  position: relative;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}

.HoverUnderlineAnimation::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #0087ca;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.HoverUnderlineAnimation:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #04020f;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #1a1831;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #000;
}

p,
ul {
  margin: 0;
}

.BellAnimation {
  transform: rotate(5deg);
  animation: ease-in 1s infinite bell-rot;
}

@keyframes bell-rot {
  0% {
    transform: rotate(0);
  }

  15% {
    transform: rotate(10deg);
  }

  30% {
    transform: rotate(-10deg);
  }

  45% {
    transform: rotate(6deg);
  }

  60% {
    transform: rotate(-6deg);
  }

  75% {
    transform: rotate(3deg);
  }

  85% {
    transform: rotate(-3deg);
  }

  87% {
    transform: rotate(1deg);
  }

  88% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(0);
  }
}

.rdw-editor-toolbar {
  background: inherit;
  border-radius: 5px;
  border-color: rgba(255, 255, 255, 0.23);

  span {
    color: black;
  }

  div {
    border-radius: 5px;
  }
}

.rdw-editor-main {
  background: inherit;
  border-radius: 5px;
  border: 1px solid rgba(255, 255, 255, 0.23);
  min-height: 300px;
  height: auto;
  padding: 0 13px;
  max-height: 60vh;
}

.rdw-dropdown-optionwrapper {
  color: black;
}

.matchedCard {
  box-shadow: 0 0 15px 5px rgba(150, 147, 217, 0.6);
  border-radius: 5px;
}

.fc-theme-standard .fc-scrollgrid,
.fc-theme-standard td,
.fc-theme-standard th {
  border-radius: 5px;
  border: 1px solid rgba(255, 255, 255, 0.12);
}

.fc .fc-daygrid-day.fc-day-today,
.fc-timegrid-slot-lane {
  background-color: #04020f;
}
